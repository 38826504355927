import React from 'react';

import { useFormContext } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import cn from 'classnames';

import { Box } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';
import { PUBLISHER_PLATFORMS, CURRENCY as CURRENCY_MAP } from '@app/constants';
import { company as companyLib } from '@app/lib';

const PERMISSION_INPUTS = ['ages', 'interests', 'genders', 'locations', 'variables'];

type PermissionsFormPropTypes = {
  permissionInputs?: Array<string>;
  platform?: string;
};

const PermissionsForm: React.FC<PermissionsFormPropTypes> = ({ permissionInputs = PERMISSION_INPUTS, platform }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.PermissionsForm',
  };
  const { company } = useAuth();
  const { t } = useLocale();
  const { watch, setValue } = useFormContext();
  const permissionsWatch = watch('permissions') ?? [];
  const currency = companyLib.currency(company);

  function hasPermission(input: string) {
    const permissionValue = `targeting_${input}:edit`;

    return permissionsWatch?.includes(permissionValue);
  }

  function handleToggle(input: string) {
    const permissionValue = `targeting_${input}:edit`;

    if (hasPermission(input)) {
      const filteredPermissions = permissionsWatch.filter((permission: string) => permission !== permissionValue);

      setValue('permissions', filteredPermissions);
      return;
    }

    setValue('permissions', [...permissionsWatch, permissionValue]);
  }

  function setMinPrice(input: number) {
    setValue('minPrice', input);
  }

  return (
    <Box>
      <span className="text-sm font-semibold text-gray-900">{t('form.label', SCOPE_OPTIONS)}</span>
      {permissionInputs.map((input) => (
        <Switch.Group key={input}>
          <div className="flex items-center mt-4">
            <Switch
              checked={hasPermission(input)}
              onChange={() => handleToggle(input)}
              className={cn(
                'relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2',
                {
                  'bg-blue-300': hasPermission(input),
                  'bg-gray-400': !hasPermission(input),
                }
              )}
            >
              <span
                className={cn('inline-block h-4 w-4 transform rounded-full bg-white transition-transform', {
                  'translate-x-6': hasPermission(input),
                  'translate-x-1': !hasPermission(input),
                })}
              />
            </Switch>
            <Switch.Label className="text-sm font-medium text-gray-800 ml-3">
              {t(`form.${platform === PUBLISHER_PLATFORMS.SMART && input === 'interests' ? 'keywords' : input}.label`, SCOPE_OPTIONS)}
            </Switch.Label>
          </div>
          {input === 'variables' && hasPermission('variables') && (
            <>
              <div className="ml-14 text-sm mt-2">{t('form.variables.minPrice', SCOPE_OPTIONS)}</div>
              <div className="relative mt-1">
                <b>
                  <span className="absolute left-16 top-[50%] translate-y-[-50%]">{CURRENCY_MAP[currency]}</span>
                </b>
                <input
                  type="number"
                  className="rounded ml-14 pl-6 pr-2 py-2 text-blue-600 font-bold [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  onChange={(e) => setMinPrice(Number(e.target.value))}
                ></input>
              </div>
            </>
          )}
        </Switch.Group>
      ))}
    </Box>
  );
};

export default PermissionsForm;
