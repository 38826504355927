import React, { useCallback, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Button, Form, Icons, Modal } from '@app/components';
import { api, useAuth, useLocale } from '@app/hooks';
import { LOCALES, GENDERS, GEO_LOCATION_TYPE, AGE, DYNAMIC_LOCATION_ADS_LOCATION_TYPES, PUBLISHER_PLATFORMS } from '@app/constants';
import { date, number, date as dateLib, interests } from '@app/lib';
import type { Campaign as CampaignType } from '@app/api';

import InterestInput from './InterestInput';
import PermissionsForm from './PermissionsForm';
import ObjectivesForm from './ObjectivesForm';
import GroupSelect from './GroupSelect';
import AudienceModal from './AudienceModal';
import Platform from 'components/Templates/Form/Platform';

type SettingsFormPropTypes = {
  campaign?: CampaignType;
  isEdit?: boolean;
  isDynamicLocationAds?: boolean;
};

const MIN_RANGE_VALUE = 1;
const MAX_RANGE_VALUE = 80;
const DEFAULT_RANGE_VALUE = 5;
const DYNAMIC_LOCATION_ADS_START_TIME_OFFSET = 2;

const SettingsForm: React.FC<SettingsFormPropTypes> = ({ campaign, isEdit, isDynamicLocationAds }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm',
  };
  const { t } = useLocale();
  const { companyId } = useAuth();
  const {
    register,
    unregister,
    formState: { errors },
    watch,
    control,
    setValue,
    getValues,
  } = useFormContext();
  const ageMinWatch = watch('ageMin');
  const ageMaxWatch = watch('ageMax');
  const startTimeWatch = watch('startTime');
  const geoLocationTypeWatch = watch('geoLocationType');
  const plusWeekToNow = date.ISOtoJSDate(date.plusFromNow({ days: 7 }));
  const defaultStopTime = campaign?.stop_time ? dateLib.ISOtoJSDate(campaign?.stop_time) : plusWeekToNow;
  const defaultStartTime = campaign?.start_time ? dateLib.ISOtoJSDate(campaign?.start_time) : new Date();
  const defaultGender = campaign?.targeting.gender ? campaign?.targeting.gender : GENDERS.ALL;
  const defaultAgeMin = campaign?.targeting.age_min ? campaign?.targeting.age_min : AGE.DEFAULT_AGE_MIN;
  const defaultAgeMax = campaign?.targeting.age_max ? campaign?.targeting.age_max : AGE.DEFAULT_AGE_MAX;
  const defaultGeoType = campaign?.geo_location_type ? campaign?.geo_location_type : GEO_LOCATION_TYPE.CITY;
  const defaultInterest = campaign?.targeting.interests ? interests.dataToInput(campaign?.targeting) : [];
  const defaultGroup = campaign?.allowed_group_ids ? campaign?.allowed_group_ids : [];
  const stopTimeWatch = watch('stopTime', defaultStopTime);
  const isUsingStopTimeWatch = watch('useStopTime', !!errors.stopTime);
  const hasAttendedBranches = !!campaign?.attended_branches_count && campaign?.attended_branches_count > 0;
  const DEFAULT_LANGUAGES = campaign?.targeting?.locales ?? [19, 1001];
  const LOCATION_VALUES = [
    {
      label: t('form.geoLocationType.options.free', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.FREE,
    },
    {
      label: t('form.geoLocationType.options.range', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.RANGE,
    },
    {
      label: t('form.geoLocationType.options.city', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.CITY,
    },
  ];
  const PLATFORMS = [
    {
      key: PUBLISHER_PLATFORMS.FACEBOOK,
      label: t('labels.facebook', { scope: 'components.Templates.Form.index' }),
      Icon: Icons.Facebook,
    },
    {
      key: PUBLISHER_PLATFORMS.INSTAGRAM,
      label: t('labels.instagram', { scope: 'components.Templates.Form.index' }),
      Icon: Icons.Instagram,
    },
  ];
  const { mutate: createAudienceMutate } = api.useCreateAudience({
    onSuccess: () => {
      setAudienceSaveModal(false);
    },
  });
  const [audienceSaveModal, setAudienceSaveModal] = useState(false);
  const [audienceListModal, setAudienceListModal] = useState(false);
  const platformsWatch = watch('platforms');

  useEffect(() => {
    if (!isEdit) {
      if (!isDynamicLocationAds) {
        const timer = setInterval(() => {
          setValue('startTime', new Date());
        }, 60 * 1000);
        return () => {
          clearInterval(timer);
        };
      }
    }
  }, []);

  useEffect(() => {
    if (!stopTimeWatch) {
      setValue('useStopTime', !!campaign?.stop_time);
    } else if (isEdit && !!campaign?.stop_time) {
      setValue('useStopTime', true);
    }
  }, [campaign?.stop_time]);

  useEffect(() => {
    if (campaign?.permissions) {
      setValue('permissions', campaign?.permissions);
    }
  }, [campaign?.permissions]);

  useEffect(() => {
    if (campaign?.allowed_objectives) {
      setValue('objectives', campaign?.allowed_objectives);
    }
  }, [campaign?.allowed_objectives]);

  useEffect(() => {
    if (isUsingStopTimeWatch === false) {
      unregister('stopTime');
    }
  }, [isUsingStopTimeWatch]);

  useEffect(() => {
    if (GEO_LOCATION_TYPE.CITY === geoLocationTypeWatch) {
      unregister('defaultLocationRange');
    }
  }, [geoLocationTypeWatch]);

  const defaultStartTimeByCampaignType = useCallback(() => {
    if (isDynamicLocationAds) {
      return campaign?.start_time
        ? dateLib.ISOtoJSDate(campaign?.start_time)
        : dateLib.ISOtoJSDate(dateLib.plusFromNow({ hours: DYNAMIC_LOCATION_ADS_START_TIME_OFFSET }));
    }

    return defaultStartTime;
  }, [isDynamicLocationAds, campaign?.start_time]);

  const filterPassedTime = (time: Date) => {
    const currentDate = defaultStartTimeByCampaignType();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const minDate = useCallback(() => {
    if (!isDynamicLocationAds) {
      return undefined;
    }

    return campaign?.start_time
      ? dateLib.ISOtoJSDate(campaign?.start_time)
      : dateLib.ISOtoJSDate(dateLib.plusFromNow({ hours: DYNAMIC_LOCATION_ADS_START_TIME_OFFSET }));
  }, [isDynamicLocationAds, campaign?.start_time]);

  function handlePlatformClick(platform: string) {
    if (!Array.isArray(platformsWatch)) {
      return;
    }

    if (platformsWatch.includes(platform) && platformsWatch.length === 1) {
      return;
    }

    if (platformsWatch.includes(platform)) {
      setValue('platforms', [...platformsWatch.filter((item: string) => item !== platform)]);
      return;
    }

    setValue('platforms', [...platformsWatch, platform]);
  }

  function submitAudience() {
    const formData = getValues();
    const audience = {
      name: formData.audienceName,
      platform: 'facebook',
      fields: {
        interests: formData.interests,
        age_min: formData.ageMin,
        age_max: formData.ageMax,
        gender: formData.gender,
        locales: formData.locales,
      },
    };

    createAudienceMutate({
      companyId: companyId,
      audienceCreateInput: {
        audience,
      },
    });
  }
  return (
    <>
      <Box>
        <Platform platforms={PLATFORMS} value={platformsWatch} onClick={handlePlatformClick} />
      </Box>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder="Name"
          {...register('name', {
            required: { value: !isEdit, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          defaultValue={campaign?.name}
          disabled={isEdit}
        />
      </Box>
      <Box>
        <Form.Date
          id="startTime"
          name="startTime"
          label={t('form.startTime.label', SCOPE_OPTIONS)}
          dateFormat="dd/MM/yyyy HH:mm"
          rules={{
            required: { value: true, message: t('form.startTime.errors.required', SCOPE_OPTIONS) },
            validate: {
              greater: (date: Date) => {
                const now = dateLib.ISOtoJSDate(dateLib.minusFromNow({ minutes: 1 }));
                date >= now || t('form.startTime.errors.greater', SCOPE_OPTIONS);
              },
            },
          }}
          disabled={isEdit && hasAttendedBranches}
          control={control}
          error={errors.startTime}
          defaultValue={defaultStartTimeByCampaignType()}
          showTimeSelect
          timeIntervals={1}
          minDate={minDate()}
          filterTime={isDynamicLocationAds ? filterPassedTime : undefined}
        />
        <Form.Checkbox id="useStopTime" className="mt-4" {...register('useStopTime')} label={t('form.useStopTime.label', SCOPE_OPTIONS)} />
        {isUsingStopTimeWatch && (
          <Form.Date
            showTimeSelect
            timeIntervals={1}
            id="stopTime"
            name="stopTime"
            label={t('form.stopTime.label', SCOPE_OPTIONS)}
            dateFormat="dd/MM/yyyy HH:mm"
            rules={{
              required: { value: true, message: t('form.stopTime.errors.required', SCOPE_OPTIONS) },
              validate: {
                greater: (date: Date) => date >= startTimeWatch || t('form.stopTime.errors.greater', SCOPE_OPTIONS),
              },
            }}
            control={control}
            className="mt-4"
            error={errors.stopTime}
            defaultValue={defaultStopTime}
          />
        )}
      </Box>
      <div className="flex items-center justify-between mb-2">
        <div className="text-5 font-semibold">{t('form.targetAudience.label', SCOPE_OPTIONS)}</div>
        <button onClick={() => setAudienceListModal(true)} className="text-blue-300">
          {t('form.targetAudience.useAudience', SCOPE_OPTIONS)}
        </button>
      </div>
      <Box>
        <Form.Select
          name="gender"
          label={t('form.gender.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.gender.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={Object.values(GENDERS).map((gender) => ({
            label: t(`genders.${gender.toLocaleLowerCase()}`),
            value: gender,
          }))}
          error={errors.gender}
          defaultValue={defaultGender}
        />
        <div className="grid grid-cols-2 gap-4 w-full mt-4">
          <Form.Select
            name="ageMin"
            label={t('form.ageMin.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.ageMin.errors.required', SCOPE_OPTIONS) },
              min: { value: 18, message: t('form.ageMin.errors.min', { ...SCOPE_OPTIONS, value: 18 }) },
              validate: (age: number) => {
                if (age <= ageMaxWatch) {
                  return true;
                }

                return t('form.ageMin.errors.max', SCOPE_OPTIONS);
              },
            }}
            control={control}
            options={number.generateInRange(18, 65).map((index) => ({ value: index, label: String(index) }))}
            error={errors.ageMin}
            defaultValue={defaultAgeMin}
          />

          <Form.Select
            name="ageMax"
            label={t('form.ageMax.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.ageMax.errors.required', SCOPE_OPTIONS) },
              max: { value: 65, message: t('form.ageMax.errors.max', { ...SCOPE_OPTIONS, value: 65 }) },
              validate: (age: number) => {
                if (age >= ageMinWatch) {
                  return true;
                }
                return t('form.ageMax.errors.min', SCOPE_OPTIONS);
              },
            }}
            control={control}
            options={number.generateInRange(18, 65).map((index) => ({ value: index, label: String(index) }))}
            error={errors.ageMax}
            defaultValue={defaultAgeMax}
          />
        </div>
        <Form.Select
          name="locales"
          label={t('form.locales.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.gender.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          className="mt-4"
          isMulti={true}
          options={LOCALES.map((locale) => ({ value: locale.key, label: locale.name }))}
          error={errors.locales}
          defaultValue={DEFAULT_LANGUAGES}
        />
        <InterestInput defaultValue={defaultInterest} />
      </Box>
      <div className="flex items-center mb-4">
        <button onClick={() => setAudienceSaveModal(true)} className="ml-auto text-blue-300 flex itemscenter gap-2">
          <Icons.Disk /> {t('form.targetAudience.saveAudience', SCOPE_OPTIONS)}
        </button>
      </div>
      {!isDynamicLocationAds && (
        <>
          <Box>
            <Form.Select
              name="geoLocationType"
              label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
              rules={{
                required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
              }}
              control={control}
              options={LOCATION_VALUES}
              error={errors.geoLocationType}
              defaultValue={defaultGeoType}
            />
            {geoLocationTypeWatch !== GEO_LOCATION_TYPE.CITY && (
              <Form.Range
                rules={{
                  required: true,
                }}
                className="mt-4"
                name="defaultLocationRange"
                label={t('form.defaultLocationRange.label', SCOPE_OPTIONS)}
                id="defaultLocationRange"
                defaultValue={campaign?.targeting.default_location_range ?? DEFAULT_RANGE_VALUE}
                control={control}
                minValue={MIN_RANGE_VALUE}
                maxValue={MAX_RANGE_VALUE}
                error={errors.defaultLocationRange}
              />
            )}
          </Box>
          <PermissionsForm />
          <GroupSelect defaultValue={defaultGroup} />
          <ObjectivesForm isEdit={isEdit} defaultObjectives={campaign?.allowed_objectives} />
        </>
      )}
      {isDynamicLocationAds && (
        <>
          {geoLocationTypeWatch === DYNAMIC_LOCATION_ADS_LOCATION_TYPES.LOCATION && (
            <Box>
              <Form.Range
                rules={{
                  required: true,
                }}
                name="defaultLocationRange"
                label={t('form.defaultLocationRange.label', SCOPE_OPTIONS)}
                id="defaultLocationRange"
                defaultValue={DEFAULT_RANGE_VALUE}
                control={control}
                minValue={MIN_RANGE_VALUE}
                maxValue={MAX_RANGE_VALUE}
                error={errors.defaultLocationRange}
              />
            </Box>
          )}
        </>
      )}
      <AudienceModal isOpen={audienceListModal} onClose={() => setAudienceListModal(false)} />
      <Modal isOpen={audienceSaveModal} onClose={() => setAudienceSaveModal(false)}>
        <div className="py-5 px-4 flex flex-col gap-3">
          <Form.Input
            type="text"
            id="audienceName"
            label="Hedef Kitle İsmi"
            placeholder="Hedef Kitle İsmi"
            {...register('audienceName', {
              required: { value: !isEdit, message: t('form.name.errors.required', SCOPE_OPTIONS) },
            })}
            requiredSign={true}
          />
          <Button label="Kaydet" theme="gray" className="ml-auto" block={false} onClick={submitAudience}></Button>
        </div>
      </Modal>
    </>
  );
};

export default SettingsForm;
